import {
  AfterViewInit,
  Component,
  DestroyRef,
  inject,
  input,
  OnChanges,
  OnInit,
  output,
  SimpleChanges,
} from "@angular/core";
import { ReportingService } from "../../reporting/reporting.service";
import {
  ClosePlugin,
  ContentControl,
  InitAutocompleteDictionary,
  InsertVariable,
  PLUGINS_GUIDS,
  SearchAndReplace,
} from "./utils";
import { SharedService } from "../shared.service";
import { debounceTime } from "rxjs/operators";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ReportParagraph } from "../../model";
import { NewTemplateComponent } from "../new-template/new-template.component";
import { MatDialog } from "@angular/material/dialog";
import { WsService } from "../../ws.service";
import { getAppType } from "../shared-functions";
import { AppConfigService } from "../../app-config.service";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ReportParagraphComponent } from "../report-paragraph/report-paragraph.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

declare let DocsAPI: any;

@Component({
  selector: "ft-report-editor",
  templateUrl: "reporter.component.html",
  imports: [
    MatButtonModule,
    MatIconModule,
    ReportParagraphComponent,
    TranslateModule,
    MatProgressSpinnerModule,
  ],
  styleUrl: "reporter.component.scss",
})
export class ReporterComponent implements OnInit, AfterViewInit, OnChanges {
  private static isCompile: boolean;

  #editor: any;
  readonly emptyReport = input<string>(undefined);
  readonly fileId = input<string>(undefined);
  readonly templateModel = input<number>(undefined);

  documentTitle = input<string>();
  studyInstanceUID = input<string>();
  procedureType = input<string>();
  procedureCode = input<string>();
  approved = input<boolean>();
  editable = input<boolean>();
  patientData = input<any>();
  examData = input<any>();
  templateMode = input<number>();
  ccData = input<any>();
  radiologistData = input<any>();

  editorInitialized = output<string>();

  private documentKey: string;
  private users = {};
  private reportId: any;
  searchingParagraphs: boolean;

  editorStopped: boolean = false;
  waitingSeconds: number = 60;
  private waitingInterval: any;
  readonly cvis: boolean;
  readonly paragraphAutocompleteEnabled: boolean;

  private static get uiTheme(): string {
    return JSON.parse(localStorage.getItem("theme")) || "";
  }

  #destroyRef = inject(DestroyRef);
  #service = inject(ReportingService);
  #shared = inject(SharedService);
  #config = inject(AppConfigService);
  #translate = inject(TranslateService);
  #ws = inject(WsService);
  #dialog = inject(MatDialog);

  constructor() {
    this.#destroyRef.onDestroy(() => {
      this.closePlugins();
      this.#editor.destroyEditor();
    });

    this.cvis = getAppType(this.#config.logo) === "cvis";
    this.paragraphAutocompleteEnabled =
      this.#config.paragraphAutocompleteEnabled;

    this.#service.variableInsert
      .asObservable()
      .pipe(debounceTime(250), takeUntilDestroyed(this.#destroyRef))
      .subscribe(this.insertVariables.bind(this));

    this.#ws
      .observeTopic("editor")
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((res) => {
        if (res.response === "1") {
          this.editorStopped = true;
          this.waitingInterval = setInterval(() => {
            if (this.waitingSeconds > 0) this.waitingSeconds -= 1;
          }, 1000);
        }
      });
  }

  public onRefresh() {
    this.editorStopped = false;
    clearInterval(this.waitingInterval);
    this.waitingSeconds = 60;
  }

  private insertVariables(variable: string) {
    document["frameEditor"].postMessage(InsertVariable(variable), "*");
    if (variable.includes("QR_CODE")) {
      this.insertQrCode(variable);
    }
  }

  ngOnInit() {
    ReporterComponent.isCompile = this.templateMode() === 0;
  }

  ngAfterViewInit() {
    this.#shared
      .getEditorUsers()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((data) => (this.users["users"] = data));
  }

  searchParagraphs() {
    document.getElementById("p-sidenav").classList.toggle("visible");
    this.searchingParagraphs = !this.searchingParagraphs;
  }

  ngOnChanges(changes: SimpleChanges) {
    const { fileId, templateModel, emptyReport } = changes;

    const user = JSON.parse(localStorage.getItem("user"));

    if (fileId && user) {
      const split = fileId.currentValue.split("_");

      const [reportId, templateId] = split;
      this.reportId = reportId;

      if (reportId === "P") {
        const filename =
          split.length > 2 ? fileId.currentValue.replace("P_", "") : templateId;
        this.#service
          .getFileModel("0", filename, "P", user.id, user.fullName, "{}")
          .pipe(takeUntilDestroyed(this.#destroyRef))
          .subscribe((value) => this.openFile(value));
      } else if (reportId != "T") {
        this.#service
          .getFileModel(
            reportId,
            templateId,
            templateModel &&
              templateModel.firstChange &&
              emptyReport &&
              emptyReport.currentValue !== "EMPTY"
              ? "R"
              : "T",
            user.id,
            user.fullName,
            "{}",
          )
          .pipe(takeUntilDestroyed(this.#destroyRef))
          .subscribe((value) => this.openFile(value));
      } else
        this.#service
          .getFileModel("0", templateId, reportId, user.id, user.fullName, "{}")
          .pipe(takeUntilDestroyed(this.#destroyRef))
          .subscribe((value) => this.openFile(value));
    }
  }

  public openFile(config: any) {
    if (this.#editor) this.#editor.destroyEditor();

    const onOutdatedVersion = (event: any) => {
      console.log("Outdated version", event);
      location.reload();
    };

    const onError = (event: any) => {
      if (event) console.log(event.data);
    };

    const onRequestEditRights = () => {
      console.log("Request edit rights ..");
      alert(
        "Please request edit rights from an administrator to modify this document !",
      );
    };

    const onAppReady = () => {
      this.editorInitialized.emit(this.documentKey);
      setTimeout(() => {
        if (ReporterComponent.isCompile) this.compileVariables();
      }, 3000);
    };

    const onDocumentStateChange = (_: any) => {
      if (ReporterComponent.isCompile) this.compileVariables();
    };
    const onInfo = (data: any) => {
      if (data && data.data && data.data.getConfig) {
        console.log("Getting config...");
        this.#editor.serviceCommand("getConfig", config.document);
      }
    };
    const onRequestUsers = () => {
      this.#editor.setUsers(this.users);
    };
    const onRequestHistory = () =>
      this.#service
        .getReportHistory(this.reportId)
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe((history) => {
          console.log("history ", history);
          this.#editor.refreshHistory(history);
        });
    const onRequestRestore = (event: any) => {
      const version = event.data.version;

      this.#service
        .restoreVersion(this.reportId, version)
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe(onRequestHistoryClose);
    };
    const onRequestHistoryData = (event: any) => {
      const ver = event.data;

      this.#service
        .getReportHistoryData(ver)
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe((data) => {
          console.log("history data ", data);
          this.#editor.setHistoryData(data);
        });
    };
    const onRequestHistoryClose = () => document.location.reload();
    const onRequestCreateNew = (ev: any) => {
      console.log(ev);
    };

    config.events = {
      onAppReady: onAppReady,
      onDocumentStateChange: onDocumentStateChange,
      onRequestEditRights: onRequestEditRights,
      onError: onError,
      onOutdatedVersion: onOutdatedVersion,
      onInfo: onInfo,
      onRequestUsers: onRequestUsers,
      // onRequestHistory: onRequestHistory,
      // onRequestHistoryData: onRequestHistoryData,
      // onRequestHistoryClose: onRequestHistoryClose,
      onRequestRestore: onRequestRestore,
      onRequestCreateNew: onRequestCreateNew,
      onPluginsReady: () => {
        if (this.cvis) setTimeout(() => this.compileContentControls(), 2000);
        if (this.paragraphAutocompleteEnabled)
          setTimeout(
            () =>
              this.#service
                .getTemplatesParagraphs()
                .pipe(takeUntilDestroyed(this.#destroyRef))
                .subscribe(this.initEditorParagraphsDictionary),
            2000,
          );
      },
    };

    this.documentKey = config.document.key;

    const _documentTitle = this.documentTitle();
    if (_documentTitle) config.document.title = _documentTitle;

    config.mode = this.editable() ? "edit" : "view";
    config.editorConfig.mode = config.mode;

    const uiTheme = ReporterComponent.uiTheme;

    const THEMES = {
      light: "theme-fire-light",
      dark: "theme-dark",
      undefined: "theme-light",
    };

    config.editorConfig.customization.uiTheme = THEMES[uiTheme];

    this.#editor = new DocsAPI.DocEditor("reporter", config);
  }

  private compileVariables() {
    if (ReporterComponent.isCompile) {
      const patientData = this.patientData();
      const examData = this.examData();
      const _radiologistData = this.radiologistData();

      if (patientData && examData) {
        const variablesData = [...patientData, ...examData];
        variablesData.forEach((it) =>
          document["frameEditor"].postMessage(
            SearchAndReplace(it.key, it.value),
            "*",
          ),
        );
      }

      if (_radiologistData)
        _radiologistData.forEach((item: any) =>
          document["frameEditor"].postMessage(
            SearchAndReplace(item.key, item.value),
            "*",
          ),
        );
    }
  }

  private initEditorParagraphsDictionary(data: any) {
    document["frameEditor"].postMessage(InitAutocompleteDictionary(data), "*");
  }

  public compileContentControls(): void {
    const _ccData = this.ccData();
    if (ReporterComponent.isCompile && _ccData)
      document["frameEditor"].postMessage(
        ContentControl("replaceAllContentControlsTag", {
          values: _ccData,
        }),
        "*",
      );
  }

  public replaceContentControlWithTag(fieldTag: any) {
    document["frameEditor"].postMessage("replaceContentControlTag", {
      tag: fieldTag.tag,
      value: fieldTag.value,
    });
  }

  public addContentControlWithTag(ccTag: string) {
    document["frameEditor"].postMessage("addContentControlWithTag", {
      tag: ccTag,
    });
  }

  private closePlugins() {
    Object.keys(PLUGINS_GUIDS).forEach((key) =>
      document["frameEditor"].postMessage(ClosePlugin(key), "*"),
    );
  }

  private insertQrCode(variable: any) {
    this.#service
      .getQrCode(this.studyInstanceUID())
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((value) => {
        this.#editor.insertImage({
          c: "add",
          fileType: "png",
          url: value.qr_code,
        });

        setTimeout(() =>
          document["frameEditor"].postMessage(
            SearchAndReplace(
              variable,
              `${this.#translate.instant("LINK")}: ${value.external_url} ▬ ${this.#translate.instant("PASSWORD")}: ${value.password}`,
            ),
            "*",
          ),
        );
      });
  }

  insertParagraph(paragraph: ReportParagraph) {
    const p = paragraph.text.toString();
    document["frameEditor"].postMessage(
      InsertVariable(String(p).split("\n").join(" ")),
      "*",
    );
  }

  // Demo how to connect to editor via connector.
  private executeMethodOnEditor() {
    const connector = this.#editor.createConnector();
    connector.executeMethod("GetCurrentWord", [], (word) => {
      console.log(`[METHOD] GetCurrentWord: ${word}`);
    });
  }

  saveReportAsTemplate() {
    this.#dialog
      .open(NewTemplateComponent, { data: this.reportId })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((value) => console.log(value));
  }
}
